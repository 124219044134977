import React from "react";

import { Button, Checkbox, Form, Grid, Input, Row, theme, Typography } from "antd";

import { LockOutlined, MailOutlined, UserOutlined } from "@ant-design/icons";
import HttpService from "../../services/HttpService";
import { useNavigate } from "react-router-dom";

const { useToken } = theme;
const { useBreakpoint } = Grid;
const { Text, Title, Link } = Typography;

export default function App() {
    const { token } = useToken();
    const navigate = useNavigate();
    const screens = useBreakpoint();
    const httpService = new HttpService();

    const onFinish = async (values: any) => {
        try {
            const response = await httpService.login(values);
            const token = response.token;
            var isReadOnly = true;
            if (!response.isReadOnly || response.isReadOnly === "0") {
                isReadOnly = false;
            }

            localStorage.setItem("token", token);
            localStorage.setItem("isReadOnly", JSON.stringify(isReadOnly));

            navigate("/");
        } catch (error) {
            console.error("Login failed:", error);
        }
    };

    const styles = {
        container: {
            margin: "0 auto",
            padding: screens.md ? `${token.paddingXL}px` : `${token.sizeXXL}px ${token.padding}px`,
            width: "380px"
        },
        footer: {
            marginTop: token.marginLG,
            textAlign: "center",
            width: "100%"
        },
        forgotPassword: {
            float: "right"
        },
        header: {
            marginBottom: token.marginXL
        },
        section: {
            alignItems: "center",
            backgroundColor: token.colorBgContainer,
            display: "flex",
            height: screens.sm ? "100vh" : "auto",
            padding: screens.md ? `${token.sizeXXL}px 0px` : "0px"
        },
        text: {
            color: token.colorTextSecondary
        },
        title: {
            fontSize: screens.md ? token.fontSizeHeading2 : token.fontSizeHeading3
        }
    };

    return (
        <section style={styles.section}>
            <div style={styles.container}>
                <div style={styles.header}>
                    <Row justify={'center'}>
                        <img style={{ width: '70%' }} src="/logo2.png" alt="Logo" />
                    </Row>

                    <Title style={styles.title}>Sign in</Title>
                </div>
                <Form
                    name="normal_login"
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    layout="vertical"
                    requiredMark="optional"
                >
                    <Form.Item
                        name="email"
                        rules={[
                            {
                                type: "email",
                                required: true,
                                message: "Please input your email!",
                            },
                        ]}
                    >
                        <Input
                            prefix={<MailOutlined />}
                            placeholder="email"
                        />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: "Please input your Password!",
                            },
                        ]}
                    >
                        <Input.Password
                            prefix={<LockOutlined />}
                            type="password"
                            placeholder="Password"
                        />
                    </Form.Item>
                    <Form.Item style={{ marginBottom: "0px" }}>
                        <Button block={true} type="primary" htmlType="submit">
                            Log in
                        </Button>

                    </Form.Item>
                </Form>
            </div>
        </section>
    );
}