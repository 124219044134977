import React, { useRef, useState } from 'react';
import { SertifikasiModel } from '../../sertifikasi.type';
import LabelTemplate from './LabelTemplate';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import ReactDOM from 'react-dom';
import QRCode from 'qrcode';

interface ExportLabelByModalContentProps {
    onCancel: () => void;
    sertifikasi: SertifikasiModel;
}

const ExportLabelByModalContent: React.FC<ExportLabelByModalContentProps> = ({ sertifikasi }) => {
    const noSeriAwal = sertifikasi.noSeriAwal!.split('.')[1];
    const noSeriAkhir = sertifikasi.noSeriAkhir!.split('.')[1];
    const totalDocuments = (parseInt(noSeriAkhir) - parseInt(noSeriAwal)) + 1;
    const contentRef = useRef<HTMLDivElement>(null);
    const [progress, setProgress] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);

    const generateQRCode = async (code: string, retries = 3): Promise<string | null> => {
        try {
            return await QRCode.toDataURL(`https://q.benihraja.com/${code}`, { margin: 1 });
        } catch (error) {
            console.error("Failed to generate QR code:", error);
            if (retries > 0) {
                console.log(`Retrying... (${3 - retries + 1})`);
                return new Promise((resolve) => {
                    setTimeout(async () => {
                        resolve(await generateQRCode(code, retries - 1));
                    }, 1000);
                });
            }
            return null;
        }
    };

    const generatePDF = async (sertifikasi: SertifikasiModel) => {
        setLoading(true);
        const pdf = new jsPDF('landscape', 'in', [9.5, 4.5]);
        let noSeri = noSeriAwal;
        const qrCode = await generateQRCode(sertifikasi.code!);

        for (let i = 0; i < totalDocuments; i++) {
            if (contentRef.current) {
                ReactDOM.render(<LabelTemplate item={{ ...sertifikasi, noSeriAwal: noSeri }} qrCodeDataURL={qrCode} />, contentRef.current);
                noSeri = (parseInt(noSeri) + 1).toString();

                await html2canvas(contentRef.current, { scale: 1 }).then(canvas => {
                    const imgData = canvas.toDataURL('image/jpeg');
                    const imgWidth = 9.5;
                    const imgHeight = (canvas.height * imgWidth) / canvas.width;
                    pdf.addImage(imgData, 'JPEG', 0, 0, imgWidth, imgHeight);
                    if (i < totalDocuments - 1) {
                        pdf.addPage();
                    }
                });

                // ReactDOM.unmountComponentAtNode(contentRef.current);
            }
            setProgress(Math.round(((i + 1) / totalDocuments) * 100));
        }

        const filename = `Labels_${sertifikasi.noBerkas}.pdf`;
        pdf.save(filename);
        setLoading(false);
    };

    const generateServerSidePDF = async (sertifikasi: SertifikasiModel) => {
        const { noBerkas, noSeriAwal, noSeriAkhir } = sertifikasi;

        // Check for missing parameters
        if (!noBerkas || !noSeriAwal || !noSeriAkhir) {
            console.error("Missing parameters in sertifikasi object.");
            return;
        }

        // Extract only the part after the last period
        const startNoSeri = parseInt(noSeriAwal.split('.').pop()!); // Extract and convert "0123" to 123
        const endNoSeri = parseInt(noSeriAkhir.split('.').pop()!);   // Extract and convert "0124" to 124

        // Validate the extracted series numbers
        if (!startNoSeri || !endNoSeri) {
            console.error("Invalid series numbers after processing.");
            return;
        }

        // Construct the URL
        const url = `https://api.benihraja.com/sertifikasi/export?noBerkas=${encodeURIComponent(noBerkas)}&startNoSeri=${encodeURIComponent(startNoSeri)}&endNoSeri=${encodeURIComponent(endNoSeri)}`;
        console.log("Generated URL:", url); // Debug log for URL

        // Open the generated URL in a new tab
        window.open(url, "_blank");
    };



    return (
        <div>
            <div style={{ padding: '20px', textAlign: 'center', backgroundColor: '#f9f9f9', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                <div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Total Document: {totalDocuments}</div>
                <button
                    onClick={() => generateServerSidePDF(sertifikasi)}
                    style={{
                        padding: '10px 20px',
                        fontSize: '16px',
                        color: '#fff',
                        backgroundColor: '#007bff',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        transition: 'background-color 0.3s'
                    }}
                    onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#0056b3'}
                    onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#007bff'}
                    disabled={loading}
                >
                    {loading ? 'Generating...' : 'Generate PDF'}
                </button>
                {progress > 0 && progress < 100 && (
                    <div style={{ marginTop: '10px', fontSize: '16px' }}>
                        <div style={{ width: '100%', backgroundColor: '#e0e0e0', borderRadius: '5px', overflow: 'hidden' }}>
                            <div style={{ width: `${progress}%`, height: '10px', backgroundColor: '#007bff', transition: 'width 0.3s' }} />
                        </div>
                        <div style={{ marginTop: '5px' }}>{progress}%</div>
                    </div>
                )}
            </div>
            <div ref={contentRef} style={{ position: 'absolute', left: '-9999px', top: '-9999px' }} />
        </div>
    );
}

export default ExportLabelByModalContent; 